/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================
SLUGS ESTAO SOB TESTES

*/

export default [{
        url: "/home",
        name: "Home",
        slug: "home",
        icon: "HomeIcon"
    },
    {
        url: "/assunto",
        name: "Assuntos",
        slug: "assunto",
        i18n: "assunto",
        icon: "ListIcon"
    },
    {
        url: "/agenda-lembrete",
        name: "Lembretes",
        slug: "lembrete",
        i18n: "lembrete",
        icon: "BellIcon"
    },
    {
        header: "Comercial",
        icon: "FileTextIcon",
        slug: "comercial",
        i18n: "comercial",
        items: [{
                url: "/kanban",
                name: "Kanban Principal",
                slug: "kanban",
                i18n: "kanban",
                icon: "LayoutIcon"
            },
            {
                url: "/kanban-renovacao",
                name: "Para Renovacao",
                slug: "kanbanRenovacao",
                icon: "LayoutIcon",
                i18n: "kanbanRenovacao"
            },
            {
                url: "/documentos",
                name: "Documentos",
                slug: "documentos",
                icon: "AlignJustifyIcon",
                i18n: "documentos"
            },
            {
                url: "/cliente",
                name: "Clientes",
                slug: "clientes",
                icon: "UsersIcon",
                i18n: "clientes"
            },
            // {
            //   url: "/clienteProsp",
            //   name: "Clientes Prospecção",
            //   slug: "clienteProspeccao",
            //   icon: "UserCheckIcon",
            //   i18n: "clienteProspeccao"
            // },
            {
                name: "Levantamentos",
                icon: "TrelloIcon",
                submenu: [{
                        url: "/lev-associar",
                        name: "Associar levantamentos",
                        slug: "rotas_assoc",
                        i18n: "rotas_assoc"
                    },
                    {
                        url: "/lev-confirmacao",
                        name: "Confirmar levantamentos",
                        slug: "lev-confirmacao",
                        i18n: "lev-confirmacao"
                    },
                    {
                        icon: "CalendarIcon",
                        url: "/agenda-lev",
                        name: "Agenda Levantamentos",
                        slug: "agenda-lev",
                        i18n: "agenda-lev"
                    },
                ]
            },
            {
                url: "/relatorio-vendas",
                name: "Relatorio de Vendas",
                slug: "relatorio-vendas",
                i18n: "relatorio-vendas",
                icon: "PieChartIcon",

            },

        ]
    },
    {
        header: "Financeiro",
        icon: "FileTextIcon",
        i18n: "financeiro",
        slug: "financeiro",
        items: [{
            url: "/financeiro",
            name: "Financeiro",
            slug: "Financeiro",
            icon: 'TrendingUpIcon',
            i18n: "Financeiro"
        }, ]
    },
    {
        header: "Serviços",
        icon: "FileTextIcon",
        i18n: "servicos",
        slug: "servicos",
        items: [

            {
                name: "PCO",
                icon: "MapPinIcon",
                submenu: [{
                        url: "/os-associar",
                        name: "Associar OS",
                        slug: "os-associar",
                        i18n: "os-associar"
                    },
                    {
                        url: "/os-confirmacao",
                        name: "Confirmar OS",
                        slug: "os-confirmacao",
                        i18n: "os-confirmacao"
                    },
                    {
                        url: "/pos-venda",
                        name: "Pós-Venda",
                        slug: "os-associar",
                        i18n: "os-associar"
                    },
                    {
                        url: "/avaliacao-tecnica",
                        name: "Avaliação",
                        slug: "avaliacao",
                        i18n: "avaliacao"
                    },
                ],

            },
            {
                icon: "CalendarIcon",
                url: "/agenda-os",
                name: "Agenda OS",
                slug: "agenda-os",
                i18n: "agenda-os"
            },
            {
                icon: "DownloadIcon",
                url: "/retorno-os",
                name: "Retorno OS",
                slug: "retorno-os",
                i18n: "retorno-os"
            },

            {
                name: "Estoque",
                icon: "DatabaseIcon",
                submenu: [{
                        url: "/movimento",
                        name: "Movimento",
                        slug: "movimento",
                        i18n: "movimento"
                    },
                    {
                        url: "/relatorio",
                        name: "Relatório produtos",
                        slug: "relatorio",
                        i18n: "relatorio"
                    },
                    {
                        url: "/relatorio-local",
                        name: "Relatório locais",
                        slug: "relatorio",
                        i18n: "relatorio"
                    }
                ]
            },
            {
                name: "Relatorio PCO",
                icon: "PieChartIcon",
                url: "/relatorio-pco",
                slug: "relatorio-pco",
                i18n: "relatorio-pco"
            },
            {
                url: "/planta",
                name: "Editor de Planta",
                slug: "planta",
                i18n: "planta",
                icon: "ImageIcon",
            },
        ]
    },
    {
        header: "Sugestões/Bugs",
        icon: "FileTextIcon",
        i18n: "backlog",
        slug: "bugs",
        items: [{
            url: "/backlog",
            name: "Reporte",
            slug: "backlog",
            i18n: "backlog",
            icon: "SmileIcon"
        },
        {
            url: "/kanban-reports",
            name: "Tickets",
            slug: "backlog",
            i18n: "backlog",
            icon: "SettingsIcon"
        }]
    },
    {
        header: "Institucional",
        icon: "FileTextIcon",
        i18n: "institucional",
        slug: "institucional",
        items: [{
            url: "/treinamento",
            name: "Treinamento",
            slug: "treinamento",
            i18n: "treinamento",
            icon: "MonitorIcon"
        }]
    },
    {
        header: "Admin",
        icon: "SettingsIcon",
        i18n: "Apps",
        slug: "admin",
        items: [
            {
                name: "Colaborador",
                icon: "UserIcon",
                submenu: [{
                        url: "/colaborador",
                        name: "Colaboradores",
                        slug: "colaborador",
                        i18n: "colaborador"
                    },
                    {
                        url: "/perfil",
                        name: "Perfis",
                        slug: "perfis",
                        i18n: "perfis"
                    },
                    {
                        url: "/metas",
                        name: "Metas",
                        slug: "metas",
                        i18n: "metas"
                    }
                ]
            },
            {
                name: "PCO",
                icon: "MapPinIcon",
                submenu: [{
                        url: "/rota-config",
                        name: "Gerenciar rotas",
                        slug: "rota",
                        i18n: "rota"
                    },
                    {
                        url: "/tecnico-turno",
                        name: "Gerenciar força",
                        slug: "tecTur",
                        i18n: "tecTur"
                    },
                    {
                        url: "/pragas",
                        name: "Pragas",
                        slug: "pragas",
                        icon: "GithubIcon",
                        i18n: "pragas"
                    },
                    {
                        url: "/produtos",
                        name: "Produtos",
                        slug: "produtos",
                        i18n: "produtos"
                    },
                    {
                        url: "/armazenamento",
                        name: "Armazenamentos",
                        slug: "armazenamento",
                        i18n: "armazenamento"
                    },
                    {
                        url: "/metodologia",
                        name: "Metodologias",
                        slug: "metodologia",
                        i18n: "metodologia"
                    },
                    {
                        url: "/metodo",
                        name: "Métodos",
                        slug: "metodo",
                        i18n: "metodo"
                    },
                    {
                        url: "/area",
                        name: "Áreas",
                        slug: "area",
                        i18n: "area"
                    },
                    {
                        url: "/tipos-dispositivo",
                        name: "Tipos de Dispositivo",
                        slug: "tipo-dispositivo",
                        i18n: "tipo-dispositivo"
                    }
                ]
            },

            {
                name: "Financeiro",
                icon: "DollarSignIcon",

                submenu: [{
                        url: "/fornecedor",
                        name: "Fornecedores",
                        slug: "fornecedor",
                        i18n: "fornecedor"
                    },
                    {
                        url: "/portador",
                        name: "Portadores",
                        slug: "portador",
                        i18n: "portador"
                    },
                    {
                        url: "/formas-pagamentos",
                        name: "Formas Pagamentos",
                        slug: "formas-pagamentos",
                        i18n: "formas-pagamentos"
                    },
                    {
                        url: "/centro-custo",
                        name: "Centro de custos",
                        slug: "centro-custo",
                        i18n: "centro-custo"
                    },
                    {
                        url: "/plano-conta",
                        name: "Plano de contas",
                        slug: "plano-conta",
                        i18n: "plano-conta"
                    }
                ]
            },
            {
                name: "Configuracoes",
                icon: "SlidersIcon",

        submenu: [
          {
            url: "/empresa",
            name: "Empresas",
            slug: "empresa",
            i18n: "empresa"
          },
          {
            url: "/frase",
            name: "Frases",
            slug: "frase",
            i18n: "frase"
          },
          {
            url: "/tipo_faturamento",
            name: "Tipos Faturamento",
            slug: "tipos_faturamento",
            i18n: "tipos_faturamento"
          },
          {
            url: "/controle",
            name: "Controles",
            slug: "controle",
            i18n: "controle"
          },
          {
            url: "/proveniente",
            name: "Provenientes",
            slug: "proveniente",
            i18n: "proveniente"
          },
          {
            url: "/datas-especiais",
            name: "Datas especiais",
            slug: "Datas especiais"
          },
          {
            url: "/parametro",
            name: "Parâmetros",
            slug: "parametro",
            i18n: "parametro"
          },
          {
            url: "/endereco",
            name: "Endereços",
            slug: "endereco",
            i18n: "endereco"
          },
          {
            url: "/cidade",
            name: "Cidades",
            slug: "cidade",
            i18n: "cidade"
          },
          {
            url: "/segmento",
            name: "Segmentos",
            slug: "segmento",
            i18n: "segmento"
          },
          {
            url: "/emails",
            name: "Emails",
            slug: "emails",
            il8n: "emails",
          }
        ]
      },

            {
                url: "/log",
                name: "Log do sistema",
                slug: "log",
                i18n: "log",
                icon: "EyeIcon"
            },

        ]
    },
    {
        header: "Ações",
        icon: "",
        i18n: "Ações",
        slug: "Ações",
        items: [{
            url: "/logout",
            name: "Logout",
            slug: "Logout",
            icon: 'LogOutIcon',
            i18n: "Logout"
        }, ]
    },
];